body {
  background-color: #f8f8f8;
  overflow-y: auto;
  overflow-x: hidden;
}

.Container {
  max-width: 800px;
  min-height: calc(100vh - 4rem);
  padding: 2rem;
  box-sizing: border-box;
  margin: auto;
}

header {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

footer {
  background-color: #070f1c;
  color: #ccc;
  padding: 1rem;
  height: 2rem;
}

.Container-footer {
  max-width: 800px;
  margin: auto;
  font-size: 0.7rem;
  letter-spacing: 0.01rem;
  text-align: center;
}

.name-section {
  margin-bottom: 0.5rem;
}

.name {
  display: inline;
}

.section-title {
  font-family: 'Acephimere Hotaru Condensed';
  display: inline;
  color: #070f1c88;
}

.links {
  display: flex;
  position: relative;
  flex-wrap: wrap;
}

i.fab {
  margin: 0 0.5rem;
}

i>a {
  text-decoration: none;
  font-weight: bold;
  transition: color 0.25s;
  font-family: 'Acephimere Hotaru';
  margin: 0 0.3rem;
}

i>a:link {
  color: #acc0e0;
}

i>a:visited {
  color: #acc0e0;
}

i>a:hover {
  color: #7d8ca3;
}

i>a:active {
  color: #525c6b;
}

.link, .link-disabled {
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-weight: bold;
  font-family: 'Acephimere Hotaru Condensed';
  position: relative;
  z-index: 1;
  transition: color 0.25s,
    background-color 0.5s,
    border-bottom 0.5s;
}

.link-container { position: relative; }

.link {
  border-bottom: 0.1rem solid #070f1c;
  color: #070f1c;
  background-color: #00000005;
}

.link-disabled {
  background-color: #070f1c;
  border-bottom: 0.1rem solid #495d80;
  color: white;
  cursor: default;
}

a.link, a.link-disabled { display: inline-block; }

a.link:link { color: #070f1c; }

a.link:visited { color: #070f1c; }

a.link:hover { background-color: #00000011; }

a.link:active { background-color: #00000055; }

.slidedown, .slideup {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #070f1c;
}

.slideup {
  height: 100%;
  transition: height 0.25s cubic-bezier(.02,1.72,.62,.81);
}

.slidedown {
  height: 0;
}

@media (max-width: 650px){
  header { display: block; }
}

@media (max-width: 420px){
  a.link, a.link-disabled { display: block; }
  .link, .link-disabled { padding: 0.2rem 0.5rem; margin: 0.1rem 0.1rem; }
  .link-container { width: 50%; }
  .slideup, .slidedown { left: 0.1rem; bottom: 0.1rem; width: calc(100% - 0.2rem); }
  .slideup { height: calc(100% - 0.2rem); }
}

strong {
  color: #070f1c;
}