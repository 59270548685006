@font-face {
  font-family: 'Acephimere Hotaru';
  font-style: normal;
  font-weight: 400;
  src: local('Acephimere Hotaru'),
       url('assets/fonts/acephimere-hotaru.woff') format('woff');
}

@font-face {
  font-family: 'Acephimere Hotaru';
  font-style: italic;
  font-weight: 400;
  src: local('Acephimere Hotaru Italic'),
       url('assets/fonts/acephimere-hotaru-italic.woff') format('woff');
}

@font-face {
  font-family: 'Acephimere Hotaru';
  font-style: normal;
  font-weight: 700;
  src: local('Acephimere Hotaru Bold'),
       url('assets/fonts/acephimere-hotaru-bold.woff') format('woff');
}

@font-face {
  font-family: 'Acephimere Hotaru';
  font-style: italic;
  font-weight: 700;
  src: local('Acephimere Hotaru Bold Italic'),
       url('assets/fonts/acephimere-hotaru-bold-italic.woff') format('woff');
}

@font-face {
  font-family: 'Acephimere Hotaru Condensed';
  font-style: normal;
  font-weight: 400;
  src: local('Acephimere Hotaru Condensed'),
       url('assets/fonts/acephimere-hotaru-condensed.woff') format('woff');
}

@font-face {
  font-family: 'Acephimere Hotaru Condensed';
  font-style: normal;
  font-weight: 700;
  src: local('Acephimere Hotaru Bold Condensed'),
       url('assets/fonts/acephimere-hotaru-bold-condensed.woff') format('woff');
}

body {
  margin: 0;
  font-family: 'Acephimere Hotaru', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-size: 1.1em;
}

h1 {
  color: #070f1c;
  margin-top: 0;
}