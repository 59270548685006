.heading__main {
  font-size: 2rem;
  font-style: oblique 8deg;
  font-weight: bold;
  margin-top: calc(10vh - 1rem);
  margin-bottom: 1rem;
  font-family: 'Acephimere Hotaru Condensed';
}

.heading__main > strong {
  color: #0e2449;
}

h3, p {
  margin: 0.25rem 0;
}