.badge__container {
  display: inline-block;
  color: white;
  font-weight: bold;
  font-size: 0.8rem;
  border-radius: 5px;
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;
  padding: 0.3rem;
  mix-blend-mode: difference;
}