button.button-module__button {
  padding: 0.5rem 1rem;
  font-weight: bold;
  font-family: 'Acephimere Hotaru Condensed';
  transition: 0.2s;
  border: none;
  border-bottom: 0.1rem solid #070f1c;
  color: #070f1c;
  background-color: #0001;
  cursor: pointer;
  font-size: 1rem;
}

button.button-module__button:hover {
  background-color: #0002;
}

button.button-module__button:active {
  background-color: #0005;
}