.header-content-section {
  perspective: 1000px;
}

.header-content__header {
  font-family: 'Acephimere Hotaru Condensed';
  font-weight: bold;
  font-size: 1.8rem;
  display: flex;
  align-items: flex-end;
}

.header-content__header-title {
  color: #070f1c;
  border-bottom: 2px solid #070f1c;
}

.header-content__header-line {
  flex: 1;
}

.header-content__header-line > hr {
  border: 1px dashed #00000011;
  margin: 0;
}

.header-content__header-img-collapsible, .header-content__header-img-noncollapsible {
  margin: 0.5rem 0;
}

.header-content__header-img-collapsible {
  transition: 0.2s;
  cursor: pointer;
}

.header-content__header-img-collapsible:hover {
  opacity: 0.8;
  background-color: #0001;
}

.header-content__header-img-collapsible:active {
  opacity: 0.7;
  transform: scale(0.97)
} 

.header-content__header-img-collapsible > img,
.header-content__header-img-noncollapsible > img {
  width: 100%;
  object-fit: cover;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@media (max-width: 650px){
  .header-content__header-img-collapsible, .header-content__header-img-noncollapsible {
    width: calc(100vw - 2rem);
    margin-right: calc(-50vw + 50%);
  }

  .header-content__header {
    width: calc(100vw - 2rem);
    margin-right: calc(-50vw + 50%);
  }
}

.header-content__content-visible, .header-content__content-invisible {
  margin-bottom: 2rem;

  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform-origin: top;
  overflow: hidden;
}

.header-content__content-visible {
  transform: translate(0,0) scale(1) rotateX(0);
  opacity: 1;
}

.header-content__content-invisible {
  transform: translate(0,-10px) scale(0.95) rotateX(-90deg);
  opacity: 0;
  height: 0;
}