.fonts-section {
  -webkit-overflow-scrolling: touch;
}

.fonts-section > p {
  margin-bottom: 1rem;
}

.fonts-section > p > br {
  line-height: 200%;
}

.fonts-section > hr {
  border: 1px solid #00000011;
}

.fonts-section .button-section {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}